import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор",
    className: "info-modal__element",
    value: fields.EVENT_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Название сервиса",
    tooltip: "Название сервиса",
    className: "info-modal__element",
    value: fields.EVENT_SYSTEM_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Версия сервиса",
    tooltip: "Версия сервиса",
    className: "info-modal__element",
    value: fields.EVENT_SYSTEM_VERSION,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Инициатор",
    tooltip: "Инициатор",
    className: "info-modal__element",
    value: fields.EVENT_INITIATOR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "IP-адрес",
    tooltip: "IP-адрес",
    className: "info-modal__element",
    value: fields.EVENT_IP,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип события",
    tooltip: "Типа события может принимать следующие значения: Ошибка, Событие, Действие пользователя",
    className: "info-modal__element",
    value: fields.EVENT_EVENT_TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Время события",
    tooltip: "Время события",
    className: "info-modal__element",
    value: fields.EVENT_TIME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип объекта",
    tooltip: "Тип объекта",
    className: "info-modal__element",
    value: fields.EVENT_OBJECT_TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор объекта",
    tooltip: "Идентификатор объекта",
    className: "info-modal__element",
    link: true,
    value: fields.EVENT_OBJECT_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код события",
    tooltip: "Код события",
    className: "info-modal__element_full-size",
    value: fields.EVENT_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Сообщение",
    tooltip: "Сообщение",
    className: "info-modal__element_full-size",
    childrenClassName: "info-element-wrapped",
    value: fields.EVENT_MESSAGE,
  },
];
