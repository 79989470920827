import { Module } from "vuex";
import { IRootState } from "@monorepo/monitoring/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/monitoring/src/views/EventLogView/utils/convertFiltersToApi";
import { checkFilters } from "@monorepo/monitoring/src/views/EventLogView/utils/checkFilters";
import { convertTypeToText } from "@monorepo/monitoring/src/views/EventLogView/utils/convertTypeToText";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { EVENT_TYPE } from "@monorepo/monitoring/src/views/EventLogView/types/EventTypes";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";

interface ITableState {
  filters: ITableFiltersObject;
  libraries: {
    users: Record<string, string>[];
    objects: Record<string, string>[];
    categories: Record<string, string>[];
    sources: Record<string, string>[];
    types: Record<string, string>[];
    objectTypes: Record<string, string>[];
    eventCodeList: { code: string; name: string }[];
  };
  section?: Sections;
  isOpenFilters: boolean;
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  totalLength: number;
  data: IEvent[];
  infiniteId: string;
  cells: string[];
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
}

interface ModuleOptions {
  isProfileJournalView?: boolean;
}

const defaultFilters = (options: ModuleOptions): ITableFiltersObject => ({
  sort: options.isProfileJournalView ? { eventTime: SORT_TYPE.DESC } : { date: SORT_TYPE.DESC },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX", options: ModuleOptions) => {
  const { isProfileJournalView } = options;

  switch (payload) {
    case "CSV":
      return isProfileJournalView ? QUERY_PATH.GET_USER_AUDIT_CSV_LIST : QUERY_PATH.GET_EVENT_CSV_LIST;
    case "XLSX":
      return isProfileJournalView ? QUERY_PATH.GET_USER_AUDIT_XLS_LIST : QUERY_PATH.GET_EVENT_XLS_LIST;
    default:
      return isProfileJournalView ? QUERY_PATH.GET_USER_AUDIT_ASYNC_PDF_LIST : QUERY_PATH.GET_EVENT_PDF_LIST;
  }
};

export const module = (options: ModuleOptions = {}): Module<ITableState, IRootState> => ({
  namespaced: true,
  state: (): ITableState => ({
    libraries: {
      categories: [],
      objects: [],
      sources: [],
      users: [],
      types: [
        {
          title: convertTypeToText(EVENT_TYPE.ERROR),
          value: EVENT_TYPE.ERROR,
          icon: `${environmentVariables.BASE_URL}assets/images/monitoring/typeSelect/error.svg`,
        },
        {
          title: convertTypeToText(EVENT_TYPE.MESSAGE),
          value: EVENT_TYPE.MESSAGE,
          icon: `${environmentVariables.BASE_URL}assets/images/monitoring/typeSelect/message.svg`,
        },
        {
          title: convertTypeToText(EVENT_TYPE.ACTION),
          value: EVENT_TYPE.ACTION,
          icon: `${environmentVariables.BASE_URL}assets/images/monitoring/typeSelect/action.svg`,
        },
      ],
      objectTypes: [],
      eventCodeList: [],
    },
    isOpenFilters: false,
    section: options.isProfileJournalView ? Sections.USER_PROFILE_AUDIT : Sections.EVENT,
    cells: [],
    searchTemplates: [],
    sectionCells: {} as ISection,
    filters: defaultFilters(options),
    infiniteId: new Date().toString(),
    totalLength: 0,
    data: [],
    isTableLoading: false,
    isLoadingToggleFilters: false,
  }),
  mutations: {
    ...baseMutations,
    clearFilters(state: ITableState) {
      state.filters = defaultFilters(options);
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      if (!Object.prototype.hasOwnProperty.call(payload, "date")) {
        state.filters.sort = { date: state.filters.sort.date, ...payload };
        return;
      }

      state.filters.sort = { ...state.filters.sort, date: !payload.date ? SORT_TYPE.DESC : payload.date };
    },
    addEvent(state: ITableState, payload: IEvent[]) {
      const newEvents = (payload ?? []).filter(checkFilters(state.filters.fieldFilters));

      if (!newEvents.length) {
        return false;
      }

      if (state.filters.sort.date === SORT_TYPE.DESC) {
        state.data = payload.concat(state.data);
      }

      if (state.filters.sort.date === SORT_TYPE.ASC && state.totalLength === state.data.length) {
        state.data = state.data.concat(payload);
      }
      state.totalLength = state.totalLength + payload.length;
    },
    addData(
      state: ITableState,
      payload: {
        data: IEvent[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    addUserLibItems(state: ITableState, payload: string[]) {
      state.libraries.users = convertToSelectItems(payload);
    },
    addSourcesLibItems(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.sources = convertToSelectItems(payload, "title", "code");
    },
    addObjectsLibItems(state: ITableState, payload: string[]) {
      state.libraries.objects = convertToSelectItems(payload);
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    refreshInfiniteId(state: ITableState) {
      state.infiniteId = new Date().toString();
    },
    addCategoriesLibItems(state: ITableState, payload: string[]) {
      state.libraries.categories = convertToSelectItems(payload);
    },
    addObjectList(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.objectTypes = payload;
    },
    addEventCodeList(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.eventCodeList = payload;
    },
  },
  actions: {
    ...baseActions,
    async getUserLib({ commit, state }) {
      checkExistLibrary(state.libraries.users, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_USERS_LIB);
        commit("addUserLibItems", data);
      });
    },
    async getErrorDescription({ state }, id: string) {
      // const { data } = await getQuery<{ description: string }>(QUERY_PATH.GET_ERROR_DESCRIPTION);
      // return data;
      return state.data.find((element) => element.id === id);
    },
    async getObjectsLib({ commit, state }) {
      checkExistLibrary(state.libraries.objects, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OBJECTS_LIB);
        commit("addObjectsLibItems", data);
      });
    },
    async getCategoriesLib({ commit, state }) {
      checkExistLibrary(state.libraries.categories, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_CATEGORIES_LIB);
        commit("addCategoriesLibItems", data);
      });
    },
    async getSourcesLib({ commit, state }) {
      checkExistLibrary(state.libraries.sources, async () => {
        const { data } = await getQuery<{ code: string; name: string }[]>(QUERY_PATH.GET_SOURCES_LIB);
        commit("addSourcesLibItems", data);
      });
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IEvent[]>(options.isProfileJournalView ? QUERY_PATH.GET_USER_PROFILE_JOURNAL : QUERY_PATH.GET_EVENT_LIST, params).finally(
        () => {
          commit("setTableLoading", false);
        }
      );
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getEventElement(info, payload: string) {
      const { data } = await getQuery<IEvent>(`${options.isProfileJournalView ? QUERY_PATH.GET_AUDIT_LIST : QUERY_PATH.GET_EVENT_LIST}/${payload}`);
      return data || {};
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload, options), queryParams), {
        root: true,
      });
    },
    async getObjectTypes({ commit, state }) {
      checkExistLibrary(state.libraries.objectTypes, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_AUDIT_OBJECT_TYPE);
        commit("addObjectList", data);
      });
    },
    async getEventCodes({ commit, state }) {
      checkExistLibrary(state.libraries.eventCodeList, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_EVENT_TYPE_LIB);
        commit("addEventCodeList", data);
      });
    },
  },
  getters: {
    ...baseGetters,
    objectsLib(state: ITableState) {
      return state.libraries.objects;
    },
    categoriesLib(state: ITableState) {
      return state.libraries.categories;
    },
    sourcesLib(state: ITableState) {
      return state.libraries.sources;
    },
    usersLib(state: ITableState) {
      return state.libraries.users;
    },
    typesLib(state: ITableState) {
      return state.libraries.types;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    objectTypes(state: ITableState) {
      return state.libraries.objectTypes;
    },
    eventCodeList(state: ITableState) {
      return state.libraries.eventCodeList;
    },
  },
});
