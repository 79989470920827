




import { defineComponent } from "@vue/composition-api";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { getQuery } from "@monorepo/monitoring/src/views/EventLogView/constants/getQuery";
import SortPanel from "@monorepo/uikit/src/components/tableViews/SortPanel.vue";
import { debounce } from "lodash";
import useSortPanelContainer from "@monorepo/utils/src/composables/useSortPanelContainer";

export default defineComponent({
  props: {
    id: {
      type: String,
      default: "",
    },
    sectionName: {
      type: String,
      default: "eventLogView",
    },
  },
  components: {
    SortPanel,
  },
  computed: {
    sort(): unknown {
      return this.$store.getters[`${this.sectionName}/sort`];
    },
  },
  methods: {
    getQueryDebounce: debounce(function (this: Record<string, unknown>) {
      getQuery();
    }, 500),
    changeSortComponentCb(
      this: {
        changeSort: (type: SORT_TYPE) => void;
        getQueryDebounce: () => void;
      },
      currentType: SORT_TYPE
    ) {
      this.changeSort(currentType);
      this.getQueryDebounce();
    },
  },
  setup(props, { root }) {
    const { changeSort } = useSortPanelContainer(root, props.sectionName, props.id);

    return {
      changeSort,
    };
  },
});
