import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/userProfileJournal/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  { text: "Дата", defaultWidth: 220, value: fields.EVENT_TIME, isSorted: true, isHandle: true, id: uuid() },
  { text: "Код события", defaultWidth: 300, value: fields.EVENT_CODE, isSorted: false, isHandle: true, id: uuid() },
  { text: "IP-Адрес", defaultWidth: 120, value: fields.IP_ADDRESS, isSorted: false, isHandle: true, id: uuid() },
  { text: "Сообщение", defaultWidth: 300, value: fields.MESSAGE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Тип объекта", defaultWidth: 180, value: fields.OBJECT_TYPE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Идентификатор объекта", defaultWidth: 200, value: fields.OBJECT_ID, isSorted: false, isHandle: true, id: uuid() },
];
