import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export const filtersElements = [
  {
    value: fields.EVENT_CODE,
    title: "Код события",
    type: "select",
    selectItemsField: "eventCodeListSelectItems",
    width: 386,
  },
  {
    value: fields.FILTER_EVENT_TIME,
    dateValues: [fields.FILTER_EVENT_TIME_FROM, fields.FILTER_EVENT_TIME_TO],
    title: "Период",
    tooltip: "Выбор интервала с помощью календаря",
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
    type: "datePeriod",
    width: 392,
  },
  {
    value: fields.EVENT_IP,
    title: "IP-адрес",
    type: "text",
    width: 386,
  },
  {
    value: fields.EVENT_OBJECT_ID,
    title: "Идентификатор объекта",
    type: "text",
    width: 263,
  },
  {
    value: fields.EVENT_OBJECT_TYPE,
    title: "Тип объекта",
    type: "select",
    selectItemsField: "eventTypesListSelectItems",
    width: 386,
  },
];
