export interface IEventLogFields {
  EVENT_ID: string;
  EVENT_CATEGORY: string;
  EVENT_DATE: string;
  EVENT_DESCRIPTION: string;
  EVENT_OBJ: string;
  EVENT_TYPE: string;
  EVENT_SOURCE: string;
  EVENT_USER: string;
  EVENT_ERROR_CODE: string;
  EVENT_DATE_TO: string;
  EVENT_DATE_FROM: string;
  EVENT_TIME_TO: string;
  EVENT_TIME_FROM: string;
  EVENT_OBJECT_TYPE: string;
  EVENT_CODE: string;
  EVENT_OBJECT_ID: string;
  EVENT_IP: string;
  EVENT_SYSTEM_NAME: string;
  EVENT_SYSTEM_VERSION: string;
  EVENT_INITIATOR: string;
  EVENT_EVENT_TYPE: string;
  EVENT_TIME: string;
  EVENT_MESSAGE: string;

  FILTER_EVENT_TIME: string;
  FILTER_EVENT_TIME_FROM: string;
  FILTER_EVENT_TIME_TO: string;
}

export const fields: IEventLogFields = {
  EVENT_ID: "id",
  EVENT_CATEGORY: "category",
  EVENT_DATE: "date",
  EVENT_DESCRIPTION: "description",
  EVENT_OBJ: "obj",
  EVENT_TYPE: "type",
  EVENT_SOURCE: "source",
  EVENT_USER: "user",
  EVENT_ERROR_CODE: "errorCode",
  EVENT_DATE_TO: "dateTo",
  EVENT_DATE_FROM: "dateFrom",
  EVENT_TIME_TO: "timeTo",
  EVENT_TIME_FROM: "timeFrom",
  EVENT_OBJECT_TYPE: "objectType",
  EVENT_CODE: "eventCode",
  EVENT_OBJECT_ID: "objectId",
  EVENT_IP: "initiatorIp",
  EVENT_SYSTEM_NAME: "systemName",
  EVENT_SYSTEM_VERSION: "systemVersion",
  EVENT_INITIATOR: "initiator",
  EVENT_EVENT_TYPE: "eventType",
  EVENT_TIME: "eventTime",
  EVENT_MESSAGE: "message",

  FILTER_EVENT_TIME: "eventTime",
  FILTER_EVENT_TIME_FROM: "eventTimeFrom",
  FILTER_EVENT_TIME_TO: "eventTimeTo",
};
