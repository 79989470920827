import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.EVENT_DESCRIPTION]: "",
  [fields.EVENT_DATE_FROM]: "",
  [fields.EVENT_DATE_TO]: "",
  [fields.EVENT_TIME_FROM]: "",
  [fields.EVENT_TIME_TO]: "",
  [fields.EVENT_CATEGORY]: [],
});
