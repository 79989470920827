import { onBeforeMount, ComponentInstance } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance, name: string): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch(`${name}/getObjectTypes`);
    store.dispatch(`${name}/getEventCodes`);

    if (name === "eventLogView") {
      store.dispatch(`${name}/getSourcesLib`);
      store.dispatch(`${name}/getUserLib`);
      store.dispatch(`${name}/getCategoriesLib`);
    }
  });
};

export default useGetTableLibs;
